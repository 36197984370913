import React from 'react'
import { Link } from 'gatsby'

// import { languages } from "../data/static-texts"
import slugHandler from '../api/slugHandlerBrowser'
import { MdLanguage, MdArrowDropDown } from 'react-icons/md'

const LanguageSwitcher = ({ activeLanguage, languages, slug, color }) => {

  return (
    <div className="tar-langSwitch">
        <MdLanguage className="tar-hero-icon"/>
        { activeLanguage }
        <MdArrowDropDown className="tar-hero-icon"/>

        <div className={color === "black" ? "tar-langSwitch-menu" : "tar-langSwitch-menu tar-langSwitch-menu--dark"}>
      {
      languages.map(lang => {
          const url = slugHandler(
            lang,
            slug
          );
          return (
                <Link
                key={lang}
                className={`tar-langSwitch-item ${activeLanguage === lang ? 'tar-langSwitch-item--active' : ''}`}
                to={url}
                >
                {lang}&nbsp;
                </Link>
            )
            }
      )}

        </div>
    </div>
  )
}

export default LanguageSwitcher