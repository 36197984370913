
import React, { useState } from "react"
import Logo from "./logo"
import {
  RiMenuLine
} from 'react-icons/ri'
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"
import MobileMenu from "./mobilemenu"
// import { getTranslation } from "../utils/language"
import LanguageSwitcher from "./languageSwitcher"
import SocialIcons from "./socialIcons"

function TopMenu (props){
  // console.log(props);
  const data = useStaticQuery(graphql`
  {
    settings: file(sourceInstanceName: {eq: "settings"}, name: {eq: "settings"}) {
      childMdx {
        frontmatter {
          email
          facebook
          sitename
          address
          address2
          hours
          phone
          instagram
          twitter
          googlemaplink
          googlemaphtml
        }
      }
    }
  }
`)
const settings = data.settings.childMdx.frontmatter;

let classes = "tar-hero-menu" + (props.smallLogo ? " tar-hero-menu--smallLogo": "");
let [mobileMenuVisible, setMobileMenuVisible] = useState(false);
const nav = props.translation.nav;

    return  <div className={classes}>
    <Logo color={props.color || "black"}></Logo>
    <div className="tar-hero-links tar-hero-links--desktop">
      <div className="tar-hero-menu-items fwb fflb">
      {
        nav.map(item => (
          <Link key={item.title} to={item.url} className="tar-hero-menu-item tar-link-1" activeClassName="tar-link-1--active">
            {item.title}
          </Link>
        ))
      }
      <div className="tar-hero-menu-item">
        <LanguageSwitcher activeLanguage={props.lang || "fi"} languages={props.languages} slug={props.slug} color={props.color || "black"}></LanguageSwitcher>
      </div>
      </div>
      <SocialIcons settings={settings}></SocialIcons>
    </div>
    <div className="tar-hero-links tar-hero-links--mobile">
        <button className="tar-hero-menu-item tar-hero-bar tar-link-1" onClick={()=>setMobileMenuVisible(true)}>
          <RiMenuLine className="tar-hero-icon"></RiMenuLine>
        </button>
    </div>
    <MobileMenu lang={props.lang} languages={props.languages} translation={props.translation} settings={settings} visible={mobileMenuVisible} onClose={()=>setMobileMenuVisible(false)}></MobileMenu>
  </div>
}

export default TopMenu