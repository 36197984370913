import React from 'react'
import { RiCloseLine, } from 'react-icons/ri'
import { Link } from "gatsby"
import Logo from "./logo"
import LanguageSwitcher from './languageSwitcher'
import SocialIcons from './socialIcons'
// import { getTranslation } from '../utils/language'


function MobileMenu(props) {
    let classes = "tar-mobile-menu tar-page color-black" + (props.visible ? "" : " dn");
    const nav = props.translation.nav;
    return (
        <div className={classes}>
            <div className="tar-mobile-menu-out">
                <div className="tar-hero-menu tar-hero-menu--smallLogo tar-hero-icons tar mb2">
                    <Logo color={props.color || "black"}></Logo>
                    <button className="tar-hero-menu-item tar-hero-close tar-link-1" onClick={props.onClose}>
                        <RiCloseLine className="tar-hero-icon"></RiCloseLine>
                    </button>
                </div>
                <div className="tar-mobile-menu-in">
                    <div className="tar-mobile-menu-items mb2 fwb">
                    {
                        nav.map(item => (
                        <Link key={item.title} to={item.url} className="tar-mobile-menu-item tar-link-1 mb1" activeClassName="tar-link-1--active">
                            {item.title}
                        </Link>
                        ))
                    }
                    <div className="tar-mobile-menu-item mb1">
                        <LanguageSwitcher activeLanguage={props.lang || "fi"} languages={props.languages} slug={props.slug} color={props.color || "black"}></LanguageSwitcher>
                    </div>
                    </div>
                    <SocialIcons settings={props.settings}></SocialIcons>
    
                </div>
            </div>
        </div>
    )
}


export default MobileMenu