import { kebabCase } from "lodash";

export function slugHandler(language, slug) {
  if (language === 'en') {
    return kebabCase(slug).length === 0 ? '/en/' : `/en/${kebabCase(slug)}/`
  } 
  else if (language === 'ru') {
    return kebabCase(slug).length === 0 ? '/ru/' : `/ru/${kebabCase(slug)}/`
  } 
  else {
    return kebabCase(slug).length === 0 ? '/' : `/${kebabCase(slug)}/`
  }
}

export default slugHandler;